import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PrimaryLayout from '../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import styles from './Settings.module.scss'
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { TDeliveryDays, TSettingsForm } from '../../types'
import { useDeliveryDaysQuery, useDeliveryDaysUpdateMutation } from '../../queries'
import Spinner from '../../../../shared/components/Spinner/Spinner'
import DaysSettings from '../DaysSettings/DaysSettings'
import { prepareDaysOfWeekMutation } from '../../utils/settingsUtils'

type TProps = {
    deliveryDaysData: TDeliveryDays
}

function useFormInit({ deliveryDaysData }: TProps) {
  return useForm<TSettingsForm>({
    defaultValues: { ...deliveryDaysData }
  })
}

function useOnSubmit() {
  const { mutate: deliveryDaysMutationAsync } = useDeliveryDaysUpdateMutation()
  return useCallback(async (formData: TSettingsForm) => {
    await deliveryDaysMutationAsync(prepareDaysOfWeekMutation(formData))
  }, [])
}

const Settings: FC<TProps> = ({ deliveryDaysData }) => {
  const { key } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { control, watch, getValues } = useFormInit({ deliveryDaysData })
  const weekdays = watch()
  const isUpdateAble = useMemo(() => Object.values(weekdays).some((day) => day), [weekdays])

  const submit = useOnSubmit()

  const handleNavigate = () => {
    const isNavigationHistory = key === 'default'
    if (isNavigationHistory) {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <div>
      <PrimaryLayout header={<div className={styles.title}>{t('deliverydays.title')}</div>} size="large">
        <DaysSettings control={control}/>

        <div className={styles.buttonGroup}>
          <PrimaryButton
            onClick={() => submit(getValues())}
            disabled={!isUpdateAble}
            text={t('common.update')}/>
          <SecondaryButton onClick={handleNavigate} text={t('common.cancel')}/>
        </div>
      </PrimaryLayout>
    </div>
  )
}

export default () => {
  const {
    data: deliveryDaysData,
    isLoading: isDeliveryDaysLoading
  } = useDeliveryDaysQuery()

  if (isDeliveryDaysLoading || !deliveryDaysData) {
    return (
      <div className={styles.spinnerWrap}>
        <Spinner/>
      </div>
    )
  }
  return (
    <Settings deliveryDaysData={deliveryDaysData}/>
  )
}
