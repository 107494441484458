import {
  TDaysOfWeek,
  TDeliveryDays,
  TSettingsForm
} from '../types'

const daysOfWeekMap: TDaysOfWeek[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

export const prepareDaysOfWeekMutation = (formData: TSettingsForm) => daysOfWeekMap.reduce((acc,
  daysOfWeek) => ({
  ...acc,
  [daysOfWeek]: formData[daysOfWeek] }),
  {} as TDeliveryDays)
