import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TCalendarParams, TDailyOrder, TDailyOrderDetailsParams, TOrdersCalendarData } from './types'
import { TNameId } from '../../growingPlan/BaseGrowingPlanWorkShop'
import { NO_GROWING_PLAN_OPTION } from '../BaseQuantityReports'
import { useAccountDetailsQuery } from '../../account/accountBase'

export enum QuantityReportsQueryKeys{
  OrdersCalendar = 'OrdersCalendar',
  OrdersWeekly = 'OrdersWeekly',
  DailyOrder = 'DailyOrder',
  CheckPreviousOrderExist = 'CheckPreviousOrderExist',
  SellerList = 'SellerList',
  BuyerList = 'BuyerList'
}

async function getOrdersCalendar(
  { dateFrom, dateTo, buyerId, sellerId, growingPlanId }: TCalendarParams
): Promise<TOrdersCalendarData> {
  try {
    const response = await apiClient.get('/app/orders/calendar', {
      params: {
        dateFrom,
        dateTo,
        growingPlanId: growingPlanId === NO_GROWING_PLAN_OPTION ? null : growingPlanId,
        buyerId: buyerId || null,
        sellerId: sellerId || null
      }
    })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrdersCalendarQuery = (
  { dateTo, growingPlanId, dateFrom, buyerId, sellerId }: TCalendarParams
) => {
  const key = [QuantityReportsQueryKeys.OrdersCalendar,
    growingPlanId,
    dateFrom,
    dateTo,
    buyerId,
    sellerId
  ]
  const isValidDates = !!dateFrom && !!dateTo
  const { data: accountDetails } = useAccountDetailsQuery()
  const seller = growingPlanId === NO_GROWING_PLAN_OPTION ? accountDetails?.id : sellerId

  return useQuery<TOrdersCalendarData, Error>(
    [key],
    () => getOrdersCalendar({
      dateFrom,
      dateTo,
      growingPlanId,
      sellerId: growingPlanId === NO_GROWING_PLAN_OPTION ? accountDetails?.company.id : sellerId,
      buyerId
    }),
    {
      enabled: growingPlanId === NO_GROWING_PLAN_OPTION
        ? (!!buyerId && !!seller) && isValidDates
        : !!growingPlanId && isValidDates
    }
  )
}

async function getDailyOrder(params: Partial<TDailyOrderDetailsParams>): Promise<TDailyOrder> {
  try {
    const response = await apiClient('/app/orders/daily', { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDailyOrder = (params: Partial<TDailyOrderDetailsParams>) => {
  const keys = [QuantityReportsQueryKeys.DailyOrder, params]
  return useQuery<TDailyOrder, Error>(
    keys,
    () => getDailyOrder(params),
    {
      enabled: !!params.date
    }
  )
}

async function checkPreviousOrderExist(
  params: {
    firstWeekDate: string,
    growingPlanId?: string,
    sellerId: string | null,
    buyerId: string | null
  }
): Promise<boolean> {
  try {
    const response = await apiClient<{exists: boolean}>(
      '/app/orders/weekly/check-previous-order-exist',
      { params }
    )
    return response.data.exists
  } catch (e: any) {
    throw new Error(e)
  }
}

type TProps = {
  firstWeekDate: string
  growingPlanId?: string
  sellerId: string | null,
  buyerId: string | null
  monthIndex: string
}

async function sellerList(): Promise<TNameId[]> {
  try {
    const response = await apiClient('app/orders/weekly/sellers/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useSellerListQuery = () => {
  const keys = [QuantityReportsQueryKeys.SellerList]
  return useQuery<TNameId[], Error>(keys, sellerList)
}

async function buyerList(): Promise<TNameId[]> {
  try {
    const response = await apiClient('app/companies/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useBuyerListQuery = () => {
  const keys = [QuantityReportsQueryKeys.BuyerList]
  return useQuery<TNameId[], Error>(keys, buyerList)
}
