import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PrimaryButton from '../../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import styles from '../QuantityDetails/QuantityDetails.module.scss'
import { TOrdersCalendarDataItem } from '../../../types'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'

type TProps = {
  item: TOrdersCalendarDataItem
}
const QuantityDailyOrders: FC<TProps> = ({ item }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const Button = item.orders.some((order) => order.editableForCurrentUser && order.status === 'NEGOTIATING')
    ? PrimaryButton : SecondaryButton
  const [searchParams] = useSearchParams()
  const handleClick = () => {
    const { growingPlanId, buyerId, sellerId } = Object.fromEntries(searchParams.entries())
    navigate(`daily/${growingPlanId}?buyerId=${buyerId}&sellerId=${sellerId}&date=${item.startDate}`)
  }

  return <Button onClick={handleClick} text={t('quantity.calendar.dailyOrders')} className={styles.buttonText}/>
}

export default QuantityDailyOrders
