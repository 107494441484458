import { useMemo } from 'react'
import { Calendar as BigCalendar, dateFnsLocalizer, DateLocalizer, Event } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { addWeeks, eachDayOfInterval, endOfWeek, isWithinInterval, set } from 'date-fns'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import { dayPropGetter } from '../../utils/bigCalendarUtils'
import styles from './Calendar.module.scss'
import { useQuantityReportsContext } from '../../state/QuantityReportsState'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import DateHeader from '../DateHeader/DateHeader'
import {
  dailyEvents,
  preparedDailyEvents,
  weeklyEvents
} from '../../utils/calendarEventsUtils'
import { TLanguage, useAccountDetailsQuery } from '../../../../account/accountBase'
import { useLanguageSwitcherContext } from '../../../../languageSwitcher'
import { NO_GROWING_PLAN_OPTION } from '../../../BaseQuantityReports'
import {

  dotDateFormat,
  endOfWeekCustom,
  startOfWeekCustom
} from '../../../../../shared/utils/date'
import { useGrowingPlanYear } from '../QuantityReportHeader/QuantityReportHeader'
import { TDaysOfWeek } from '../../../../settings'

const locales: {[key in TLanguage]: Locale} = {
  en, fr, de, it
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales
})

const originalVisibleDays = localizer.visibleDays.bind(localizer)

localizer.visibleDays = (date: Date, localizer: DateLocalizer) => {
  const originalVisibleDaysArray = originalVisibleDays(date, localizer)

  const start = startOfWeek(date, { weekStartsOn: 1 })

  const nextWeekStart = addWeeks(start, 1)
  const nextWeekEnd = endOfWeek(nextWeekStart, { weekStartsOn: 1 })
  const nextWeekDays = eachDayOfInterval({ start: nextWeekStart, end: nextWeekEnd })

  return [...originalVisibleDaysArray, ...nextWeekDays]
}

const Wrapper = styled.div<{ rowsToRemove?: number[] }>`
  &&&&& {
    ${({ rowsToRemove }) => rowsToRemove?.map((row) => css`
      .rbc-calendar .rbc-month-view > div:nth-of-type(${row + 1}) {
        display: none;
      }
    `)}
  }
`
function useWeeklyEvents(): Event[] {
  const {
    ordersCalendarQuery: { data: ordersCalendarData },
    weeksStartDates,
    selectedGrowingPlan, formMethods: { watch }
  } = useQuantityReportsContext()

  const { data: accountDetails } = useAccountDetailsQuery()

  const isCurrentUserSeller = selectedGrowingPlan?.seller.id === accountDetails?.company.id
  const isNoGrowingPlan = watch('growingPlan') === NO_GROWING_PLAN_OPTION
  const buyer = watch('buyer')

  return useMemo(() => {
    if (!ordersCalendarData?.orders) {
      return []
    }

    return weeklyEvents(
      ordersCalendarData.orders,
      weeksStartDates,
      isCurrentUserSeller || (isNoGrowingPlan && !!buyer)
    )
  }, [ordersCalendarData, weeksStartDates, isCurrentUserSeller])
}

function useDailyEvents(): Event[] {
  const {
    ordersCalendarQuery: { data: ordersCalendarItems },
    calendarDates
  } = useQuantityReportsContext()

  return useMemo(() => {
    if (!ordersCalendarItems?.orders) {
      return []
    }
    const sellerDeliveryDays = Object.keys(ordersCalendarItems.sellerDeliveryDays)
      .filter((key) => ordersCalendarItems.sellerDeliveryDays[key as TDaysOfWeek])

    return dailyEvents(ordersCalendarItems.orders, calendarDates, sellerDeliveryDays)
  }, [ordersCalendarItems, calendarDates])
}

function usePreparedDailyEvents() {
  const dailyEvents = useDailyEvents()
  const weeklyEvents = useWeeklyEvents()

  return useMemo(
    (): Event[] => preparedDailyEvents(dailyEvents, weeklyEvents),
    [weeklyEvents, dailyEvents]
  )
}

function useEvents(): Event[] {
  const weeklyEvents = useWeeklyEvents()
  const dailyEvents = usePreparedDailyEvents()

  return useMemo(() => [
    ...dailyEvents,
    ...weeklyEvents
  ], [weeklyEvents, dailyEvents])
}

function useCalendarDate() {
  const { formMethods: { watch } } = useQuantityReportsContext()
  const monthIndex = watch('month')
  const year = watch('year')

  return useMemo(() => set(new Date(), {
    month: Number(monthIndex),
    year: year ? Number(year) : undefined
  }), [monthIndex, year])
}

function useRowsRemove() {
  const growingPlanYear = useGrowingPlanYear()
  const { view, formMethods: { watch }, weeksStartDates } = useQuantityReportsContext()
  const year = growingPlanYear || watch('year')
  const month = watch('month')

  return useMemo(() => {
    const currentYear = new Date().getFullYear()
    const extraWeekNumber = weeksStartDates.length

    if (view === 'MONTH' || !weeksStartDates.length || currentYear !== Number(year)) {
      return [extraWeekNumber]
    }

    const foundIndex = weeksStartDates.findIndex((date) => isWithinInterval(new Date(), {
      start: startOfWeekCustom(date),
      end: endOfWeekCustom(date)
    }))

    if (foundIndex === -1) {
      return [extraWeekNumber]
    }

    const weeksStartDatesDotFormat = weeksStartDates.map(dotDateFormat)

    const remainRows = [
      weeksStartDatesDotFormat[foundIndex],
      weeksStartDatesDotFormat[foundIndex + 1]
    ]

    const rowsToRemove = weeksStartDatesDotFormat
      .map((weeksStartDate, index) => (!remainRows.includes(weeksStartDate)
        ? index + 1
        : undefined))
      .filter(Boolean) as number[]

    return rowsToRemove
  }, [month, year, weeksStartDates, view])
}
const Calendar = () => {
  const { weeksStartDates } = useQuantityReportsContext()
  const date = useCalendarDate()
  const { language } = useLanguageSwitcherContext()
  const rowsToRemove = useRowsRemove()

  return (
    <div className={styles.calendarWrapper}>
      <Wrapper rowsToRemove={rowsToRemove}>
        <BigCalendar
          key={`${weeksStartDates.length}_${language}`}
          dayPropGetter={dayPropGetter}
          tooltipAccessor="resource"
          className={styles.bigCalendar}
          style={{ height: `${(weeksStartDates.length - rowsToRemove.length) * 125}px` }}
          defaultDate={new Date()}
          date={date}
          culture="fr"
          localizer={localizer}
          events={useEvents()}
          popup={false}
          toolbar={false}
          components={{
            month: {
              header: ({ date }) => {
                const formattedDay = format(date, 'EEE', { locale: locales[language] })
                return (
                  <h1 className={styles.monthHeader}>
                    {formattedDay}
                  </h1>
                )
              },
              dateHeader: DateHeader
            }
          }}
        />
      </Wrapper>
    </div>
  )
}

export default Calendar
