import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../Interceptor'
import { TDeliveryDays } from './types'

enum QueryKeys {
  DeliveryDays = 'DeliveryDays',
  DeliveryDaysUpdate = 'DeliveryDaysUpdate',
  SettingsSellers = 'SettingsSellers',
  SettingsSellersUpdate = 'SettingsSellersUpdate'
}

async function deliveryDays(): Promise<TDeliveryDays> {
  try {
    const response = await apiClient('app/settings/delivery-days')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDeliveryDaysQuery = () => {
  const keys = [QueryKeys.DeliveryDays]

  return useQuery<TDeliveryDays, Error>(keys, deliveryDays)
}

async function deliveryDaysUpdate(deliveryDays: TDeliveryDays): Promise<void> {
  try {
    const response = await apiClient.put('app/settings/delivery-days', { ...deliveryDays })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDeliveryDaysUpdateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TDeliveryDays>(
    (deliveryDays) => deliveryDaysUpdate(deliveryDays),
    {
      mutationKey: [QueryKeys.DeliveryDaysUpdate],
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.DeliveryDays])
    }
  )
}
