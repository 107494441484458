import { useNavigate, useSearchParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import styles from './AddNewDailyOrder.module.scss'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { dotDateFormat } from '../../../../../../shared/utils/date'

const AddNewDailyOrder = ({ date }: { date: Date }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const handleClick = () => {
    const { growingPlanId, buyerId, sellerId } = Object.fromEntries(searchParams.entries())
    navigate(`daily/${growingPlanId}?buyerId=${buyerId}&sellerId=${sellerId}&date=${dotDateFormat(date)}`)
  }

  return (
    <SecondaryButton
      className={styles.buttonText}
      onClick={handleClick}
    >
      <AddIcon />
    </SecondaryButton>
  )
}

export default AddNewDailyOrder
